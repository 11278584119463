import Carousel from 'react-bootstrap/Carousel';

function MyImageCarousel({ images }) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col'>
                    <Carousel interval={null}>

                        {images && images.map((image, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img src={image} alt="" />
                                    {/*
                                    <Carousel.Caption>
                                        <h3>First slide label</h3>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                    </Carousel.Caption>
                                    */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default MyImageCarousel;