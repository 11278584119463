import MyNavBar from "../Components/MyNavBar";

function Faqs({ setPageTitle, updateBaseUrl }) {

    setPageTitle('FAQs');
    updateBaseUrl('/faqs');

    return (
        <>
            <MyNavBar />
            <div className="container mt-5">
                <div className="row ">
                    <div className="col-lg-9 bg-lightxx text-black">
                        <div className="sidebar-header pb-4">
                            <h1 className='h4 main-color'>
                                Häufig gestellte Fragen
                            </h1>
                            <p className=''>
                                <strong>
                                    Hier finden sich hoffentlich Antworten auf häufig gestellte Fragen.
                                    Wenn nicht, schreibt uns einfach eine Mail.
                                </strong>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5">
                <div className="row ">
                    <div className="col-lg-12 bg-lightxx text-black">

                        <h2 className="h5">Woher kommen die angezeigten Preise?</h2>
                        <p>
                            Die Preise stammen von der Amazon Webseite.
                            Diese Preise werden regelmäßig (mindestens 1x bis 2x pro Tag) aktualisiert.
                        </p>

                        <h2 className="h5">Für wen ist die Seite gedacht</h2>
                        <p>
                            Diese Seite bietet eine einfache Möglichkeit, die Ergebnisse nach bestimmten Eigenschaften zu filtern.
                            Sie ist für alle, die weniger Wert auf fancy Design legen, sondern eine einfache Möglichkeit suchen,
                            nach bestimmten Eigenschaften zu filtern.
                        </p>

                        <h2 className="h5">Lieferpreise</h2>
                        <p>
                            Die angezeigten Preise beinhalten keine Lieferkosten.
                            Diese werden erst im Warenkorb auf der Amazon Webseite angezeigt.
                        </p>
                        <p>
                            Grund: Wir wissen nicht, woher Du kommst, ob Du Prime Kunde ist oder nicht, etc.
                            Daher wir können die Lieferkosten nicht vorher berechnen.
                        </p>

                        <h2 className="h5">Ist das Werbung?</h2>
                        <p>
                            Kein Tracking, keine Werbebanner, keine Cookiebanner und keine Popups.
                        </p>
                        <p>
                            Diese Seite finanziert sich (hoffentlich irgendwann einmal &#128540;) über die Provisionen, die wir von Amazon bekommen.
                            Diese Provisionen sind in den Preisen von Amazon bereits enthalten.
                        </p>

                        <h2 className="h5">Mir fehlt Feature X, ich hätte gerne Filter Y, ...</h2>
                        <p>
                            Schreibt gerne eine Mail und wir schauen, was wir machen können.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faqs;