import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const PreviousLocationContext = createContext();

export function PreviousLocationProvider({ children }) {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(location);

    useEffect(() => {
        setPrevLocation(location);
    }, [location]);

    return (
        <PreviousLocationContext.Provider value={prevLocation}>
            {children}
        </PreviousLocationContext.Provider>
    );
}

export default PreviousLocationContext;