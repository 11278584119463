
const selectOptions = {

    selectOptionsStars: [
        { value: 'stars5', label: '5 Sterne' },
        { value: 'stars4', label: '4 Sterne' },
        { value: 'stars3', label: '3 Sterne' },
        { value: 'stars2', label: '2 Sterne' },
        { value: 'stars1', label: '1 Stern' }
    ],
    selectOptionsPrices: [
        { value: 'price_100', label: 'bis 100€' },
        { value: 'price_200', label: '100€ - 200€' },
        { value: 'price_300', label: '200€ - 300€' },
        { value: 'price_400', label: '300€ - 400€' },
        { value: 'price_500', label: '400€ - 500€' },
        { value: 'price_600', label: '500€ - 600€' },
        { value: 'price_700', label: '600€ oder mehr' }
    ],
    selectOptionsStorage: [
        { value: 'storage_128', label: '128GB' },
        { value: 'storage_256', label: '256GB' },
        { value: 'storage_512', label: '512GB' }
    ],
    selectOptionsDisplaySize: [
        { value: 'display_size_6_7', label: '6 und 7 Zoll' },
        { value: 'display_size_5_6', label: '5 und 6 Zoll' },
        { value: 'display_size_4_5', label: '4 und 5 Zoll' }
    ],
    selectOptionsBrand: [
        { value: 'brand_apple', label: 'Apple' },
        { value: 'brand_asus', label: 'Asus' },
        { value: 'brand_blackview', label: 'Blackview' },
        { value: 'brand_cubot', label: 'CUBOT' },
        { value: 'brand_doogee', label: 'DOOGEE' },
        { value: 'brand_fairphone', label: 'Fairphone' },
        { value: 'brand_fossibot', label: 'FOSSiBOT' },
        { value: 'brand_gigaset', label: 'Gigaset' },
        { value: 'brand_google', label: 'Google' },
        { value: 'brand_honor', label: 'HONOR' },
        { value: 'brand_hotwav', label: 'HOTWAV' },
        { value: 'brand_huawei', label: 'Huawei' },
        { value: 'brand_microsoft', label: 'Microsoft' },
        { value: 'brand_motorola', label: 'Motorola' },
        { value: 'brand_nokia', label: 'Nokia' },
        { value: 'brand_oppo', label: 'OPPO' },
        { value: 'brand_oscal', label: 'OSCAL' },
        { value: 'brand_oukitel', label: 'OUKITEL' },
        { value: 'brand_realme', label: 'realme' },
        { value: 'brand_samsung', label: 'Samsung' },
        { value: 'brand_sony', label: 'Sony' },
        { value: 'brand_ulefone', label: 'Ulefone' },
        { value: 'brand_umidigi', label: 'UMIDIGI' },
        { value: 'brand_xiaomi', label: 'Xiaomi' },
        { value: 'brand_zte', label: 'ZTE' }
    ],
    selectOptionsCpuType: [
        { value: 'cpu_type_snapdragon', label: 'Snapdragon' },
        { value: 'cpu_type_bionic', label: 'Bionic' },
        { value: 'cpu_type_mediatek', label: 'Mediatek' },
        { value: 'cpu_type_exynos', label: 'Exynos' },
        { value: 'cpu_type_kirin', label: 'Kirin' }
    ],
    selectOptionsRefreshRate: [
        { value: 'refresh_rate_60', label: '60 Hz' },
        { value: 'refresh_rate_90', label: '90 Hz' },
        { value: 'refresh_rate_120', label: '120 Hz' },
        { value: 'refresh_rate_144', label: '144 Hz' },
        { value: 'refresh_rate_165', label: '165 Hz' },
        { value: 'refresh_rate_240', label: '240 Hz' },
        { value: 'refresh_rate_360', label: '360 Hz' }
    ],
    selectOptionsBatterySize: [
        { value: 'battery_size_4000', label: '500 - 4000' },
        { value: 'battery_size_8000', label: '4000 - 8000' },
        { value: 'battery_size_12000', label: '8000 - 12000' },
        { value: 'battery_size_20000', label: '12000 oder mehr' }
    ]
};

const checbkoxOptions = {
    checkBoxStars: [
        {
            id: 'check-stars_5',
            name: 'stars5',
            label: '5 Sterne',
            propertyName: 'stars',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-stars_4',
            name: 'stars4',
            label: '4 Sterne',
            propertyName: 'stars',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-stars_3',
            name: 'stars3',
            label: '3 Sterne',
            propertyName: 'stars',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-stars_2',
            name: 'stars2',
            label: '2 Sterne',
            propertyName: 'stars',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-stars_1',
            name: 'stars1',
            label: '1 Stern',
            propertyName: 'stars',
            onChange: 'checkboxChange'
        }
    ],
    checkBoxPrices: [
        {
            id: 'check-price_up_to_100',
            name: 'price_100',
            label: 'bis 100€',
            propertyName: 'price',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-price_100_to_200',
            name: 'price_200',
            label: '100€ - 200€',
            propertyName: 'price',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-price_200_to_300',
            name: 'price_300',
            label: '200€ - 300€',
            propertyName: 'price',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-price_300_to_400',
            name: 'price_400',
            label: '300€ - 400€',
            propertyName: 'price',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-price_400_to_500',
            name: 'price_500',
            label: '400€ - 500€',
            propertyName: 'price',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-price_500_to_600',
            name: 'price_600',
            label: '500€ - 600€',
            propertyName: 'price',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-price_600_to_700',
            name: 'price_700',
            label: '600€ oder mehr',
            propertyName: 'price',
            onChange: 'checkboxChange'
        }
    ],
    checkBoxStorage: [
        {
            id: 'check-storage_128',
            name: 'storage_128',
            label: '128GB',
            propertyName: 'storage',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-storage_256',
            name: 'storage_256',
            label: '256GB',
            propertyName: 'storage',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-storage_512',
            name: 'storage_512',
            label: '512GB',
            propertyName: 'storage',
            onChange: 'checkboxChange'
        }
    ],
    checkBoxDisplaySize: [
        {
            id: 'check-display_size_6_7',
            name: 'display_size_6_7',
            label: '6 und 7 Zoll',
            propertyName: 'display_size',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-display_size_5_6',
            name: 'display_size_5_6',
            label: '5 und 6 Zoll',
            propertyName: 'display_size',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-display_size_4_5',
            name: 'display_size_4_5',
            label: '4 und 5 Zoll',
            propertyName: 'display_size',
            onChange: 'checkboxChange'
        }
    ],
    checkBoxBrand: [
        {
            id: 'check-brand_samsung',
            name: 'brand_samsung',
            label: 'Samsung',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_apple',
            name: 'brand_apple',
            label: 'Apple',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_google',
            name: 'brand_google',
            label: 'Google',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_xiaomi',
            name: 'brand_xiaomi',
            label: 'Xiaomi',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_motorola',
            name: 'brand_motorola',
            label: 'Motorola',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_sony',
            name: 'brand_sony',
            label: 'Sony',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_honor',
            name: 'brand_honor',
            label: 'HONOR',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_huawei',
            name: 'brand_huawei',
            label: 'Huawei',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_ulefone',
            name: 'brand_ulefone',
            label: 'Ulefone',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        },
        {
            id: 'check-brand_nokia',
            name: 'brand_nokia',
            label: 'Nokia',
            propertyName: 'brand',
            onChange: 'checkboxChange'
        }
    ],
    checkboxCpuType: [
        {
            id: 'check-cpu_type_snapdragon',
            name: 'cpu_type_snapdragon',
            label: 'Snapdragon',
            propertyName: 'cpu_type'
        },
        {
            id: 'check-cpu_type_bionic',
            name: 'cpu_type_bionic',
            label: 'Bionic',
            propertyName: 'cpu_type'
        },
        {
            id: 'check-cpu_type_dimensity',
            name: 'cpu_type_dimensity',
            label: 'Mediatek Dimensity',
            propertyName: 'cpu_type'
        },
        {
            id: 'check-cpu_type_helio',
            name: 'cpu_type_helio',
            label: 'Mediatek Helio',
            propertyName: 'cpu_type'
        },


        {
            id: 'check-cpu_type_exynos',
            name: 'cpu_type_exynos',
            label: 'Exynos',
            propertyName: 'cpu_type'
        },
        {
            id: 'check-cpu_type_kirin',
            name: 'cpu_type_kirin',
            label: 'Kirin',
            propertyName: 'cpu_type'
        }
    ],
    checkboxRefreshRate: [
        {
            id: 'check-refresh_rate_60',
            name: 'refresh_rate_60',
            label: '60 Hz',
            propertyName: 'refresh_rate'
        },
        {
            id: 'check-refresh_rate_90',
            name: 'refresh_rate_90',
            label: '90 Hz',
            propertyName: 'refresh_rate'
        },
        {
            id: 'check-refresh_rate_120',
            name: 'refresh_rate_120',
            label: '120 Hz',
            propertyName: 'refresh_rate'
        },
        {
            id: 'check-refresh_rate_144',
            name: 'refresh_rate_144',
            label: '144 Hz',
            propertyName: 'refresh_rate'
        },
        {
            id: 'check-refresh_rate_165',
            name: 'refresh_rate_165',
            label: '165 Hz',
            propertyName: 'refresh_rate'
        },
        {
            id: 'check-refresh_rate_240',
            name: 'refresh_rate_240',
            label: '240 Hz',
            propertyName: 'refresh_rate'
        },
        {
            id: 'check-refresh_rate_360',
            name: 'refresh_rate_360',
            label: '360 Hz',
            propertyName: 'refresh_rate'
        }
    ],
    checkboxBatterySize: [
        {
            id: 'check-battery_size_500_to_4000',
            name: 'battery_size_4000',
            label: '500 - 4000',
            propertyName: 'battery_size'
        },
        {
            id: 'check-battery_size_4000_to_8000',
            name: 'battery_size_8000',
            label: '4000 - 8000',
            propertyName: 'battery_size'
        },
        {
            id: 'check-battery_size_8000_to_12000',
            name: 'battery_size_12000',
            label: '8000 - 12000',
            propertyName: 'battery_size'
        },
        {
            id: 'check-battery_size_12000_to_20000',
            name: 'battery_size_20000',
            label: '12000 oder mehr',
            propertyName: 'battery_size'
        }
    ],
    checkboxMisc: [
        {
            "id": "check-is_refurbished",
            "name": "is_refurbished",
            "label": "Refurbished",
            "propertyName": "is_refurbished"
        },
        {
            "id": "check-sd_card",
            "name": "sd_card",
            "label": "SD-Karten Slot",
            "propertyName": "sd_card"
        },
        {
            "id": "check-dual_sim",
            "name": "dual_sim",
            "label": "Dual-SIM",
            "propertyName": "dual_sim"
        }
    ]
}

export const formConfig = {

    checkboxFormConfiguration: [
        {
            "label": "Sonstiges",
            "name": "misc",
            "source": checbkoxOptions.checkboxMisc
        }
    ],

    selectFormConfiguration: [
        {
            label: 'Preis',
            placeholder_select: 'Preis',
            name: 'price',
            source: checbkoxOptions.checkBoxPrices,
            selectOptions: selectOptions.selectOptionsPrices
        },
        {
            label: 'Speicherplatz (auch per SD-Karte)',
            placeholder_select: 'Speicherplatz',
            name: 'storage',
            source: checbkoxOptions.checkBoxStorage,
            selectOptions: selectOptions.selectOptionsStorage
        },
        {
            label: 'Displaygröße (Auswahl)',
            placeholder_select: 'Displaygröße',
            name: 'display_size',
            source: checbkoxOptions.checkBoxDisplaySize,
            selectOptions: selectOptions.selectOptionsDisplaySize
        },
        {
            label: 'CPU Typ (wo angegeben)',
            placeholder_select: 'CPU Typ',
            name: 'cpu_type',
            source: checbkoxOptions.checkboxCpuType,
            selectOptions: selectOptions.selectOptionsCpuType
        },
        {
            label: 'Bildwiederholrate',
            placeholder_select: 'Bildwiederholrate',
            name: 'refresh_rate',
            source: checbkoxOptions.checkboxRefreshRate,
            selectOptions: selectOptions.selectOptionsRefreshRate
        },
        {
            label: 'Top Marken',
            placeholder_select: 'Top Marken',
            name: 'brand',
            source: checbkoxOptions.checkBoxBrand,
            selectOptions: selectOptions.selectOptionsBrand
        },
        {
            label: 'Akku (mAh)',
            placeholder_select: 'Akku',
            name: 'battery_size',
            source: checbkoxOptions.checkboxBatterySize,
            selectOptions: selectOptions.selectOptionsBatterySize
        },
        {
            label: 'Sterne bei Amazon',
            placeholder_select: 'Sterne',
            name: 'stars',
            source: checbkoxOptions.checkBoxStars,
            selectOptions: selectOptions.selectOptionsStars
        }
    ]
}