import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function MyNavBar() {

    return (
        <Navbar expand="md" className="bg-body-tertiary border-bottom">
            <Container fluid>
                <Navbar.Brand href="/">
                    <img
                        src="/img/logo_main.png"
                        alt="logo phoneprices.info"
                        title="logo phoneprices.info"
                        height="60"
                        className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="navbar navbar-expand-md navbar-light bg-light">
                        <Nav className="me-auto">
                            <a href="/" className="nav-link main-color">Start</a>
                            {/** <Link to="/highestRated" className="nav-link main-color">Am meisten bewertet</Link>*/}
                            <Link to={`/topseller`} className="nav-link main-color">Topseller</Link>
                            <Link to={`/topaccessories`} className="nav-link main-color">Zubehör</Link>
                            <a href="https://festplattenpreise.info/" target="_blank" rel="noreferrer" className="nav-link main-color">Festplatten</a>
                            <Link to={`/faqs`} className="nav-link main-color">Faq</Link>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default MyNavBar;