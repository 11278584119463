import { Link } from "react-router-dom";
import MyNavBar from "../Components/MyNavBar";

function PageNotFound() {
    return (
        <>
            <MyNavBar />
            <div className="container mt-5 mb-5">
                <div className="row ">
                    <div className="col-lg-9 bg-lightxx text-black">
                        <div className="sidebar-header pb-4">
                            <h1 className='h4 main-color'>
                                404 - Seite nicht gefunden
                            </h1>
                            <p className=''>
                                <strong>
                                    Es tut und leid, aber gesuchte Seite existiert nicht (mehr).
                                </strong>
                            </p>
                            <p>
                                Hier geht es zur <Link to={'/'} className="main-color">Startseite</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageNotFound;