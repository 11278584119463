import { useState } from "react";

function BackToTopLink() {

    // show the button only if the user has scrolled down more than 500px
    const [showButton, setShowButton] = useState(false);
    const [className, setClassName] = useState('hidden');

    // scroll handler
    const handleScroll = () => {
        //console.log('scrolling', window.scrollY);
        if (window.scrollY > 200) {
            if (!showButton) {
                setShowButton(true);
                setClassName('visible');
            }
        } else {
            if (showButton) {
                setShowButton(false);
                setClassName('hidden');
            }
        }
    };

    // add scroll event listener
    window.addEventListener('scroll', handleScroll);

    return (
        <div id="btn-back-to-top" className={className}>
            <a href="#top">
                <i className="fa fa-arrow-up"></i>
            </a>
        </div>
    );
}

export default BackToTopLink;